import Vue from 'vue'
import VueRouter from 'vue-router'
import { generator } from '@/router/generator'
import { asyncRoutes, constantRoutes } from '@/config/router'
import { BasicLayout } from '@/layouts'

Vue.use(VueRouter)

const routeMap = {
  path: '/',
  component: BasicLayout,
  redirect: '/dashboard',
  children: generator(asyncRoutes)
}

const notFoundRoute = {
  path: '/:pathMatch(.*)',
  redirect: '/404'
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [routeMap, ...generator(constantRoutes), notFoundRoute]
})

export default router