<template>
  <a-menu
    mode="inline"
    class="nav-menu"
    :selected-keys="active"
    @select="handleSelect"
  >
    <a-menu-item v-for="item in asyncRoutesSid" :key="item.path">
      <a-icon v-if="item.meta && item.meta.icon" :type="item.meta.icon"/>
      <span v-if="item.meta && item.meta.title">{{ item.meta.title }}</span>
    </a-menu-item>
  </a-menu>
</template>

<script>
import { asyncRoutes } from '@/config/router'
import { accountStore } from '@/store/account'
export default {
  name: 'SideBar',

  data() {
    return { asyncRoutes }
  },
  watch:{

  },
  computed: {
    active() {
      for (let i = 0; i < this.$route.matched.length; i++) {
        if (asyncRoutes.findIndex(item => this.$route.matched[i].path.indexOf(item.path) > -1) > -1) {
          return [asyncRoutes.find(item => this.$route.matched[i].path.indexOf(item.path) > -1).path]
        }
      }
      return ['']
    },
    asyncRoutesSid(){
      if(accountStore().isSales){
        return asyncRoutes
      }else {
        this.$router.replace('/')
        return asyncRoutes.filter(item => item.name != 'threeMini')
      }
    }
  },

  methods: {
    handleSelect({ key }) {
      this.$router.push(key)
    }
  }
}
</script>

<style lang="less" scoped>
.nav-menu {
  :deep(li) {
    overflow: visible;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>