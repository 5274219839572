import axios from 'axios'
import { ls } from './storage'

const baseURL = '/api'

const service = axios.create({
  baseURL,
  timeout: 600000
})

service.interceptors.request.use(config => {
  let token = ls.get('Access-Token')
  if (token) {
    config.headers['Access-Token'] = token
  }
  return config
})

export { service as request, baseURL }
