export const ls = {
  get(key) {
    const item = localStorage.getItem(key)
    if (item) {
      //localStorage中存在这个key
      const info = JSON.parse(item)

      if (info.expire && info.time) {
        //如果设置了过期时间
        if (new Date().getTime() - info.time > info.expire) {
          //如果过期
          localStorage.removeItem(key)
          return null
        } else {
          //没有过期
          return info.value
        }
      } else {
        //如果没有设置过期时间
        return info.value
      }
    } else {
      //key不存在直接返回null
      return null
    }
  },

  set(data) {
    let info = { value: data.value }
    if (data.expire && data.expire > 0) {
      info = { ...info, time: new Date().getTime(), expire: data.expire }
    }
    localStorage.setItem(data.key, JSON.stringify(info))
  },

  remove(key) {
    localStorage.removeItem(key)
  }
}
