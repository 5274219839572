import { request } from '@/utils/request'
import qs from 'qs'

const prefix = '/login-api'

export function loginByPassword(payload) {
  return request.get(`${prefix}/account/passlogin?${qs.stringify(payload)}`)
}

export function loginByCode(payload) {
  return request.get(`${prefix}/account/codelogin?${qs.stringify(payload)}`)
}

export function sendCode(phone) {
  return request.post(`${prefix}/account/code/${phone}`)
}