import Vue from 'vue'
import App from '@/App.vue'
import { PiniaVuePlugin, setMapStoreSuffix } from 'pinia'
import router from '@/router'
import pinia from '@/store'
import '@/core/antd'
import '@/core/scrollbar'
import '@/permission'

Vue.config.productionTip = false
Vue.use(PiniaVuePlugin)
setMapStoreSuffix('_pinia')

new Vue({
  router,
  pinia,
  render: h => h(App)
}).$mount('#app')
