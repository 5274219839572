<template>
  <footer class="title">
    <div class="flex">
      <div class="flex" v-for="(item, index) in list" :key="index">
        <a class="item margin" :href="item.href">{{ item.title }}</a>
        <div class="line" v-if="index !== list.length - 1"></div>
      </div>
    </div>
    <div class="flex bottom">
      <div v-for="(item, index) in filList" :key="index">
        <div class="flex margin">
          <img v-if="item.image" :src="item.image" class="img" alt=""/>
          <a class="item itemA" :href="item.href" :target="item.href !== 'javaScript:void(0);' ?'_blank':''">
            {{ item.title }}
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter',

  data() {
    return {
      list: [{
        title: '联系客服',
        href: 'javaScript:void(0);'
      }, {
        title: '合作招商',
        href: 'javaScript:void(0);'
      }, {
        title: '帮助中心',
        href: 'javaScript:void(0);'
      }, {
        title: '手机端',
        href: 'javaScript:void(0);'
      }, {
        title: '隐私政策',
        href: 'javaScript:void(0);'
      }],

      filList: [{
        // TODO 瞎写的，等着找真的补上
        title: '河北云九秒网络科技有限公司 版权所有',
        href: 'javaScript:void(0);',
        image: ''
      }, {
        image: '//gw.alicdn.com/tfs/TB1jwakrbH1gK0jSZFwXXc7aXXa-20-20.png',
        title: '冀ICP备895545222号-4',
        href: 'http://www.beian.gov.cn/portal/registerSystemInfo'
      }]
    }
  }
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  width: 100%;
  padding: 20px 0;
}

.item {
  text-decoration: none;
  color: #666666;
  font-size: 12px;
}

.item:hover {
  color: #3a5ddf;
}

.margin {
  margin: 0 20px;
}

.img {
  width: 13px;
  height: 13px;
}

.line {
  width: 1px;
  height: 10px;
  background-color: #dedede;
}

.bottom {
  margin-top: 14px;
}

.itemA {
  color: #999999;
}
</style>