<template>
  <a-layout class="global-container">
    <a-layout-header class="global-header">
      <page-header/>
    </a-layout-header>

    <a-layout v-if="selected === 0">
      <dashboard/>
    </a-layout>

    <a-layout v-else-if="true">
      <a-layout-sider collapsible v-model="collapsed" theme="light">
        <side-bar/>
      </a-layout-sider>

      <a-layout>
        <a-layout-content class="main-container">
          <keep-alive>
            <router-view/>
          </keep-alive>
        </a-layout-content>
      </a-layout>
    </a-layout>

    <a-layout v-else>
      <corporation/>
    </a-layout>
  </a-layout>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import { accountStore } from '@/store/account'
import { Corporation, Dashboard } from '@/views/Dashboard'
import SideBar from '@/components/SideBar'

export default {
  name: 'BasicLayout',

  components: { PageHeader, Dashboard, Corporation, SideBar },

  data() {
    return { collapsed: false }
  },

  computed: {
    selected() {
      return accountStore().selected
    },

    current() {
      return accountStore().current
    }
  }
}
</script>

<style lang="less" scoped>
.global-container {
  width: 100%;
  height: 100%;

  .global-header {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 14px;
  }

  .side-bar {
    background: #ffffff;
  }
}
</style>