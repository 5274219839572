import { defineStore } from 'pinia'
import { ls } from '@/utils/storage'

export const socketStore = defineStore('socketStore', {
  state: () => ({
    socket: null,
    url: 'wss://api.hbyunjiumiao.com/websocket-api/socket',
    reconnect: true,
    data: {}
  }),

  actions: {
    Connect() {
      try {
        this.socket = new WebSocket(`${this.url}/${ls.get('Access-Token')}`)
        this.Init()
      } catch (e) {
        this.Reconnect()
      }
    },

    Init() {
      this.socket.onclose = () => {
        this.Reconnect()
      }
      this.socket.onmessage = e => {
        this.data = JSON.parse(e.data)
      }
    },

    Reconnect() {
      if (this.reconnect) {
        this.reconnect = false
        setTimeout(() => {
          this.Connect()
          this.reconnect = true
        }, 5000)
      }
    },

    Disconnect() {
      this.reconnect = false
      this.socket.close()
    }
  }
})