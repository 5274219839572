export const asyncRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { title: '首页', icon: 'windows' },
    component: () => import('@/views/Dashboard/SaleOffice.vue')
  }, {
    path: '/customer',
    name: 'customer',
    meta: { title: '客户', icon: 'team' },
    component: () => import('@/views/Customer')
  }, {
    path: '/project',
    name: 'project',
    meta: { title: '项目', icon: 'home' },
    component: () => import('@/views/Project'),
    children: [{
      path: '/project/house',
      name: 'house',
      meta: { title: '房源' },
      component: () => import('@/views/Project/House')
    }, {
      path: '/project/basement',
      name: 'basement',
      meta: { title: '地下室' },
      component: () => import('@/views/Project/Basement.vue')
    }, {
      path: '/project/park',
      name: 'park',
      meta: { title: '车位' },
      component: () => import('@/views/Project/Park.vue')
    }, {
      path: '/project/type',
      name: 'type',
      meta: { title: '户型' },
      component: () => import('@/views/Project/Type.vue')
    }]
  }, {
    path: '/order',
    name: 'order',
    meta: { title: '订单', icon: 'bars' },
    component: () => import('@/views/Order')
  }, {
    path: '/exhibit',
    name: 'exhibit',
    meta: { title: '展示', icon: 'mobile' },
    component: () => import('@/views/ThreeMini')
  },
  /*  {
    path: '/mini',
    name: 'mini',
    meta: { title: '小程序', icon: 'mobile' },
    component: () => import('@/views/MiniPro')
  }, */
  {
    path: '/activity',
    name: 'activity',
    meta: { title: '活动', icon: 'trophy' },
    component: () => import('@/views/Activity'),
    children: [{
      path: '/activity/marketCard',
      name: 'marketCard',
      meta: { title: '拓客活动' },
      component: () => import('@/views/Activity/MarketCard.vue')
    }, {
      path: '/activity/createActivity',
      name: 'createActivity',
      meta: { title: '活动设置' },
      component: () => import('@/views/Activity/CreateActivity.vue')
    }, {
      path: '/activity/participants',
      name: 'participants',
      meta: { title: '参与人数' },
      component: () => import('@/views/Activity/Participants.vue')
    }]
  }, {
    path: '/self',
    name: 'self',
    meta: { title: '自渠', icon: 'wechat' },
    component: () => import('@/views/SelfChannel')
  }
]

export const constantRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: { title: '用户登录' },
    component: () => import('@/views/Account/Login')
  }, {
    path: '/404',
    name: '404',
    meta: { title: '404' },
    component: () => import('@/views/Exception/404')
  }, {
    path: '/service',
    name: 'service',
    meta: { title: '发送测试账号' },
    component: () => import('@/views/Exception/Service')
  }, {
    path: '/download',
    name: 'download',
    meta: { title: '下载地址' },
    component: () => import('@/views/Exception/DownLoad')
  }
]
