import { BlankLayout } from "@/layouts";

export function generator(routeMap, parent) {
  return routeMap.map((item, index) => {
    const currentRoute = {
      path: item.path,
      name: item.name,
      component: item.component || BlankLayout,
      meta: item.meta,
      children: [],
    };

    if (item.redirect) {
      currentRoute.redirect = item.redirect;
    }

    if (index === 0 && parent && !parent.redirect) {
      parent.redirect = item.path;
    }

    if (item.children && item.children.length > 0) {
      currentRoute.children = generator(item.children, currentRoute);
    }

    return currentRoute;
  });
}
