<template>
  <a-config-provider :locale="zh_CN">
    <keep-alive>
      <router-view/>
    </keep-alive>
  </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { socketStore } from '@/store/socket'

moment.locale('zh-cn')
export default {
  data() {
    return {
      zh_CN
    }
  },

  mounted() {
    socketStore().Connect()
  }
}
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  color: #303133;
}
</style>
